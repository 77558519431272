import { IError, ISuccessResponse } from './types';
import { logout } from './user/request';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { routesEnum } from 'pages/Routes';
import { toast } from 'react-toastify';
import cookieOptions, { refreshTokenCookieOptions, tokenCookieOptions } from "../utils/cookieOptions";

// for multiple requests
let isRefreshing = false;
let failedQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void }[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const axiosInstance = axios.create({
  baseURL: process.env.WEB_API_URL,
  withCredentials: false,
});


export const baseAxios = axios.create({
  baseURL: process.env.WEB_API_URL,
  withCredentials: false,
});

const handleSuccess = (res: AxiosResponse<ISuccessResponse>) => {
  if (res.data?.code < 200 && res.data?.code > 299) {
    return Promise.reject(res.data);
  }

  const result = res.data;
  if (result?.pagination) {
    result.data = {
      list: result.data,
      pagination: result?.pagination,
    };
  }
  return result;
};

const handleError = async (error: AxiosError<IError>) => {
  const originalRequest = error.config;
  // @ts-ignore
  if (error?.response?.data.type === 'TOKEN_EXPIRED' && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(async (token) => {
          _.set(originalRequest, 'headers.Authorization', 'Bearer ' + token);
          if (typeof originalRequest.data === 'string') {
            _.set(originalRequest, 'data', JSON.parse(originalRequest.data));
          }
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    _.set(originalRequest, '_retry', true);
    isRefreshing = true;
    return new Promise(function (resolve, reject) {
      axios
        .post(`${process.env.WEB_API_URL}/users/token/refresh`, {
          refreshToken: Cookies.get('refreshToken'),
        })
        .then((res) => {
          const { data } = res;
          const token = data?.data?.token;
          const refreshToken = data?.data?.refreshToken;
          Cookies.set('token', token, tokenCookieOptions);
          Cookies.set('refreshToken', refreshToken, refreshTokenCookieOptions);
          axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          _.set(originalRequest, 'headers.Authorization', 'Bearer ' + token);

          if (typeof originalRequest.data === 'string') {
            _.set(originalRequest, 'data', JSON.parse(originalRequest.data));
          }
          processQueue(null, token);
          resolve(axiosInstance(originalRequest));
        })
        .catch(async (error) => {
          processQueue(error, null);
          try {
            await logout();
            localStorage.clear();
            Cookies.remove('token');
            Cookies.remove('refreshToken');
            sessionStorage.clear();
          } catch (error) {
            localStorage.clear();
            Cookies.remove('token');
            Cookies.remove('refreshToken');
            sessionStorage.clear();
          }

          toast.success('Session expired!', {
            autoClose: 1000,
          });
          window.location.href = routesEnum.home;

          reject(error);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  if (error?.response?.data.type === 'UNAUTHORIZED') {
    localStorage.clear();
    try {
      await logout();
      localStorage.clear();
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      sessionStorage.clear();
    } catch (error) {
      localStorage.clear();
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      sessionStorage.clear();
    }

    Cookies.remove('token');
    Cookies.remove('refreshToken');
    sessionStorage.clear();

    toast.success('Session expired!', {
      autoClose: 1000,
    });
    window.location.href = routesEnum.home;
  }

  const data = error?.response?.data;
  if (typeof data?.message === 'string') {
    data.message = [data.message];
  }
  return Promise.reject(data);
};

axiosInstance.interceptors.response.use(handleSuccess, handleError);

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = Cookies.get('token');
    config = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: config.data,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

baseAxios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = Cookies.get('token');
    config = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: config.data,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

export const convertToFormData = (data: { string: string }) => {
  const bodyFormData = new FormData();
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      bodyFormData.append(key, value || '');
    }
  }
  return bodyFormData;
};
