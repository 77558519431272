export const LOCAL_STORAGE = {
  token: 'token',
  user: 'user',
  isInvestor: 'isInvestor',
  isIssuer: 'isIssuer',
  type: 'type',
  type1: 'type',
  type2: 'type',
  refreshToken: 'refreshToken',
  status: 'status',
  dormantPopup: 'dormantPopup',
};

export const DocumentTypeIssuer =
  'Identification document (NRIC/ Passport/ Certificate of Incorporation) of Shareholder, Controlling Person, Directors, Authorized Person and Senior Management';
export const BoardResolution = 'Board Resolution';
export const PHONE_PREFIX = ['+60', '+84'];

export const LIST_COUNTRY = [
  'ABKHAZIA',
  'AFGHANISTAN',
  'ALAND ISLANDS',
  'ALBANIA',
  'ALGERIA',
  'AMERICAN SAMOA',
  'ANDORRA',
  'ANGOLA',
  'ANGUILLA',
  'ANTARCTICA',
  'ANTIGUA AND BARBUDA',
  'ARGENTINA',
  'ARMENIA',
  'ARUBA',
  'ASCENSION',
  'ASHMORE AND CARTIER ISLANDS',
  'AUSTRALIA',
  'AUSTRALIAN ANTARCTIC TERRITORY',
  'AUSTRIA',
  'AZERBAIJAN',
  'BAHAMAS',
  'BAHRAIN',
  'BAKER ISLAND',
  'BANGLADESH',
  'BARBADOS',
  'BELARUS',
  'BELGIUM',
  'BELIZE',
  'BENIN',
  'BERMUDA',
  'BHUTAN',
  'BOLIVIA',
  'BOSNIA AND HERZEGOVINA',
  'BOTSWANA',
  'BOUVET ISLAND',
  'BRAZIL',
  'BRITISH ANTARCTIC TERRITORY',
  'BRITISH INDIAN OCEAN TERRITORY',
  'BRITISH SOVEREIGN BASE AREAS',
  'BRITISH VIRGIN ISLANDS',
  'BRUNEI',
  'BULGARIA',
  'BURKINA FASO',
  'BURMA (REPUBLIC OF THE UNION OF MYANMAR)',
  'BURUNDI',
  'CAMBODIA',
  'CAMEROON',
  'CANADA',
  'CAPE VERDE',
  'CARIBBEAN NETHERLANDS',
  'CAYMAN ISLANDS',
  'CENTRAL AFRICAN REPUBLIC',
  'CHAD',
  'CHILE',
  'CHINA',
  'CHRISTMAS ISLAND',
  'CLIPPERTON ISLAND',
  'COCOS (KEELING) ISLANDS',
  'COLOMBIA',
  'COMOROS',
  'CONGO (REPUBLIC OF)',
  'COOK ISLANDS',
  'CORAL SEA ISLANDS',
  'COSTA RICA',
  "COTE D'IVOIRE (IVORY COAST)",
  'CROATIA',
  'CUBA',
  'CURACAO',
  'CYPRUS',
  'CZECH REPUBLIC',
  'DEMOCRATIC REPUBLIC OF THE CONGO',
  'DENMARK',
  'DJIBOUTI',
  'DOMINICA',
  'DOMINICAN REPUBLIC',
  'ECUADOR',
  'EGYPT',
  'EL SALVADOR',
  'EQUATORIAL GUINEA',
  'ERITREA',
  'ESTONIA',
  'ETHIOPIA',
  'FALKLAND ISLANDS',
  'FAROE ISLANDS',
  'FIJI',
  'FINLAND',
  'FRANCE',
  'FRENCH GUIANA',
  'FRENCH POLYNESIA',
  'FRENCH SOUTHERN AND ANTARCTIC LANDS',
  'FRENCH SOUTHERN TERRITORIES',
  'GABON',
  'GAMBIA',
  'GEORGIA',
  'GERMANY',
  'GHANA',
  'GIBRALTAR',
  'GREECE',
  'GREENLAND',
  'GRENADA',
  'GUADELOUPE',
  'GUAM',
  'GUATEMALA',
  'GUERNSEY',
  'GUINEA',
  'GUINEA-BISSAU',
  'GUYANA',
  'HAITI',
  'HEARD AND MCDONALD ISLANDS',
  'HONDURAS',
  'HONG KONG',
  'HOWLAND ISLAND',
  'HUNGARY',
  'ICELAND',
  'INDIA',
  'INDONESIA',
  'IRAN',
  'IRAQ',
  'IRELAND',
  'ISLE OF MAN',
  'ISRAEL',
  'ITALY',
  'JAMAICA',
  'JAPAN',
  'JARVIS ISLAND',
  'JERSEY',
  'JOHNSTON ATOLL',
  'JORDAN',
  'KAZAKHSTAN',
  'KENYA',
  'KINGMAN REEF',
  'KIRIBATI',
  'KOSOVO',
  'KUWAIT',
  'KYRGYZSTAN',
  'LAOS',
  'LATVIA',
  'LEBANON',
  'LESOTHO',
  'LIBERIA',
  'LIBYA',
  'LIECHTENSTEIN',
  'LITHUANIA',
  'LUXEMBOURG',
  'MACAU',
  'MACEDONIA',
  'MADAGASCAR',
  'MALAWI',
  'MALAYSIA',
  'MALDIVES',
  'MALI',
  'MALTA',
  'MARSHALL ISLANDS',
  'MARTINIQUE',
  'MAURITANIA',
  'MAURITIUS',
  'MAYOTTE',
  'MEXICO',
  'MICRONESIA',
  'MIDWAY ISLANDS',
  'MOLDOVA',
  'MONACO',
  'MONGOLIA',
  'MONTENEGRO',
  'MONTSERRAT',
  'MOROCCO',
  'MOZAMBIQUE',
  'NAGORNO-KARABAKH',
  'NAMIBIA',
  'NAURU',
  'NAVASSA ISLAND',
  'NEPAL',
  'NETHERLANDS',
  'NETHERLANDS ANTILLES',
  'NEW CALEDONIA',
  'NEW ZEALAND',
  'NICARAGUA',
  'NIGER',
  'NIGERIA',
  'NIUE',
  'NORFOLK ISLAND',
  'NORTH KOREA',
  'NORTHERN CYPRUS',
  'NORTHERN MARIANA ISLANDS',
  'NORWAY',
  'OMAN',
  'PAKISTAN',
  'PALAU',
  'PALESTINE',
  'PALMYRA ATOLL',
  'PANAMA',
  'PAPUA NEW GUINEA',
  'PARAGUAY',
  'PERU',
  'PETER I ISLAND',
  'PHILIPPINES',
  'PITCAIRN',
  'PITCAIRN ISLANDS',
  'POLAND',
  'PORTUGAL',
  'PRIDNESTROVIE (TRANSNISTRIA)',
  'PUERTO RICO',
  'QATAR',
  'QUEEN MAUD LAND',
  'REUNION',
  'ROMANIA',
  'ROSS DEPENDENCY',
  'RUSSIAN FEDERATION',
  'RWANDA',
  'SAINT BARTHELEMY',
  'SAINT HELENA',
  'SAINT KITTS AND NEVIS',
  'SAINT LUCIA',
  'SAINT MARTIN (FRANCE)',
  'SAINT MARTIN (NETHERLANDS)',
  'SAINT PIERRE AND MIQUELON',
  'SAINT VINCENT AND GRENADINES',
  'SAMOA',
  'SAN MARINO',
  'SAO TOME AND PRINCIPE',
  'SAUDI ARABIA',
  'SENEGAL',
  'SERBIA',
  'SEYCHELLES',
  'SIERRA LEONE',
  'SINGAPORE',
  'SLOVAKIA',
  'SLOVENIA',
  'SOLOMON ISLANDS',
  'SOMALIA',
  'SOMALILAND',
  'SOUTH AFRICA',
  'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
  'SOUTH KOREA',
  'SOUTH OSSETIA',
  'SOUTH SUDAN',
  'SPAIN',
  'SRI LANKA',
  'SUDAN',
  'SURINAME',
  'SVALBARD AND JAN MAYEN ISLANDS',
  'SWAZILAND',
  'SWEDEN',
  'SWITZERLAND',
  'SYRIA',
  'TAIWAN',
  'TAJIKISTAN',
  'TANZANIA',
  'THAILAND',
  'TIMOR-LESTE',
  'TOGO',
  'TOKELAU',
  'TONGA',
  'TRINIDAD AND TOBAGO',
  'TRISTAN DA CUNHA',
  'TUNISIA',
  'TURKEY',
  'TURKMENISTAN',
  'TURKS AND CAICOS ISLANDS',
  'TUVALU',
  'UGANDA',
  'UKRAINE',
  'UNITED ARAB EMIRATES',
  'UNITED KINGDOM',
  'UNITED STATES MINOR OUTLYING ISLANDS',
  'UNITED STATES OF AMERICA',
  'UNITED STATES VIRGIN ISLANDS',
  'UNKNOWN',
  'URUGUAY',
  'UZBEKISTAN',
  'VANUATU',
  'VATICAN',
  'VENEZUELA',
  'VIETNAM',
  'WAKE ISLAND',
  'WALLIS AND FUTUNA ISLANDS',
  'WESTERN SAHARA',
  'YEMEN',
  'ZAMBIA',
  'ZIMBABWE',
];

export const TabInvestorSubcription = {
  Market: 'market',
  Dashboard: 'dashboard',
  TopUp: 'top-up',
};

export const TabInvestorProfile = {
  Dashboard: 'dashboard',
  Info: 'info',
  Transfer: 'transfer',
  Actions: 'actions',
  News: 'news',
  Mesg: 'mesg',
  Setting: 'settings',
};

export const TabIssuerSubcription = {
  CompanyProfile: 'company-profile',
  ShareHolder: 'share-holder',
  Documents: 'documents',
  KYCSubmission: 'kyc-submisstion',
};

export const IPAY88 = {
  ORIGIN_IPAY: 'http://18.141.252.52:5000/investors/ipay88',
  HOST_IPAY: 'payment.ipay88.com.my',
  MERCHANT_KEY: 'hz4YI3v7UE',
  MERCHANT_CODE: 'M28168',
  PAYMENT_ID: '2',
  LANG: 'UTF-8',
  SIGNATURE_TYPE: 'SHA256',
  RESPONSE_URL: 'https://kldx.varvn.tech/investors/ipay88/response',
  BACKEND_URL: 'https://kldx.varvn.tech/api/investors/ipay88/response',
};

export const KYC_STATUS_ENUM = {
  0: 'URL Not Opened',
  1: 'Processing',
  2: 'Completed',
  3: 'Expired',
};

export const KYC_STATUS_RESULT = {
  0: 'Reject',
  1: 'Approved',
  2: 'Not Available',
};

export const TYPE_LOGIN = {
  investor: '813f1db28c1a7cd418b9d269284b7eb7aa93b3370ba0137cb89b8f56aae55b45',
  issuer: '444c6b0e11ef20ee4a63e61518954db2dbe37488015a1d279e95223e4201e082',
};

export const EASY_PIN = [
  '111111',
  '222222',
  '333333',
  '444444',
  '555555',
  '666666',
  '777777',
  '888888',
  '999999',
  '123456',
  '234567',
  '345678',
  '456789',
  '987654',
  '876543',
  '765432',
  '654321',
  '543210',
  '000000',
];

export const PROJECT_STATUS = {
  ARCHIVED: 'ARCHIVED',
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  CLOSED: 'CLOSED',
  OPENING_SOON: 'OPENING SOON',
  COOLING_OFF: 'COOLING OFF',
  ALLOCATION: 'ALLOCATION',
  SUCCESSFUL: 'SUCCESSFUL',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
};

export const CASH_TRANSACTIONS_ACTIVITY = {
  PERIODIC_PAYMENT: 'Periodic payment',
  OMNIBUS_SUBSCRIPTION: 'OMNIBUS_SUBSCRIPTION',
  OMNIBUS_SEND: 'OMNIBUS_SEND',
  OMNIBUS_RECEIVED: 'OMNIBUS_RECEIVED',
  OMNIBUS_SUBSCRIPTION_CANCEL: 'OMNIBUS_SUBSCRIPTION_CANCEL',
  OMNIBUS_SUBSCRIPTION_REFUND: 'OMNIBUS_SUBSCRIPTION_REFUND',
  OMNIBUS_TOPUP: 'OMNIBUS_TOPUP',
  OMNIBUS_WITHDRAW: 'OMNIBUS_WITHDRAW',
  OMNIBUS_COUPON_PAYMENT: 'OMNIBUS_COUPON_PAYMENT',
  OMNIBUS_DIVIDEND_PAYMENT: 'OMNIBUS_DIVIDEND_PAYMENT',
  SUBSCRIPTION: 'Subscription',
  SUBSCRIPTION_CANCEL: 'Subscription cancel',
  SUBSCRIPTION_REFUND: 'Subscription refund',
  TOPUP: 'Top up',
  WITHDRAW: 'Withdraw',
  COUPON_PAYMENT: 'Coupon payment',
  DIVIDEND_PAYMENT: 'Dividend payment',
  BUY_LIMIT: 'Buy Limit',
  BUY_ORDER: 'Buy Order',
  SELL_ORDER: 'Sell Order',
};

export const CASH_TRANSACTIONS_ACTIVITY_WALLET = {
  PERIODIC_PAYMENT: 'PERIODIC_PAYMENT',
  SUBSCRIPTION: 'SUBSCRIPTION',
  SUBSCRIPTION_CANCEL: 'SUBSCRIPTION_CANCEL',
  SUBSCRIPTION_REFUND: 'SUBSCRIPTION_REFUND',
  TOPUP: 'TOPUP',
  WITHDRAW: 'WITHDRAW',
  COUPON_PAYMENT: 'COUPON_PAYMENT',
  DIVIDEND_PAYMENT: 'DIVIDEND_PAYMENT',
  NONCUMULATIVE_REDEMPTION_TRANSFER: 'NONCUMULATIVE_REDEMPTION_TRANSFER',
  SELL_ORDER_REALRECEIVE: 'SELL_ORDER_REALRECEIVE',
  SELL_ORDER_REALPAY: 'SELL_ORDER_REALPAY',
  BUY_ORDER_REALPAY: 'BUY_ORDER_REALPAY',
  BUY_ORDER_REALRECEIVE: 'BUY_ORDER_REALRECEIVE',
};

export const TRANSACTION_TEXTS = {
  BUY_ORDER: 'Buy Order',
  SELL_ORDER: 'Sell Order',
};

export const SECONDARY_TRADING_WALLET = {
  BUY: 'Buy',
  SELL: 'Sell',
  BUY_REFUND: 'Buy Refund',
};

export const ISSUER_CASH_TRANSACTIONS_ACTIVITY = {
  PERIODIC_PAYMENT: 'Periodic Payment',
  SUBSCRIPTION: 'Subscription',
  SUBSCRIPTION_CANCEL: 'Subscription Cancel',
  SUBSCRIPTION_REFUND: 'Subscription Refund',
  TOPUP: 'Top up',
  WITHDRAW: 'Withdraw',
  COUPON_PAYMENT: 'Coupon Payment',
  DIVIDEND_PAYMENT: 'Dividend Payment',
};

export const CASH_TRANSACTIONS_STATUS = {
  SUCCESSFUL: 'SUCCESSFUL',
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  NEW: 'NEW',
  PENDING: 'PENDING',
  TO_PROCESS: 'TO_PROCESS',
  REFUNDED: 'REFUNDED',
  REJECTED_REFUNDING: 'REJECTED_REFUNDING',
  REJECTED: 'REJECTED',
  REJECTED_PENDING_APPROVAL_NO_REFUND: 'REJECTED_PENDING_APPROVAL_NO_REFUND',
  REJECTED_TO_REFUND: 'REJECTED_TO_REFUND',
  REJECTED_PENDING_APPROVAL_REFUND: 'REJECTED_PENDING_APPROVAL_REFUND',
  REQUIRE_MANUAL_CONFIRMATION: 'REQUIRE_MANUAL_CONFIRMATION',
  PENDING_IPAY88: 'PENDING_IPAY88',
  FAILURE: 'FAILURE',
  COMPLETED: 'COMPLETED',
};

export const TRADING_ACCOUNT_STATUS = {
  SUCCESSFUL: 'SUCCESSFUL',
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  NEW: 'NEW',
  PENDING: 'PENDING',
  TO_PROCESS: 'TO_PROCESS',
  REFUNDED: 'REFUNDED',
  REJECTED_REFUNDING: 'REJECTED_REFUNDING',
  REJECTED: 'REJECTED',
  REJECTED_PENDING_APPROVAL_NO_REFUND: 'REJECTED_PENDING_APPROVAL_NO_REFUND',
  REJECTED_TO_REFUND: 'REJECTED_TO_REFUND',
  REJECTED_PENDING_APPROVAL_REFUND: 'REJECTED_PENDING_APPROVAL_REFUND',
  REQUIRE_MANUAL_CONFIRMATION: 'REQUIRE_MANUAL_CONFIRMATION',
  PENDING_IPAY88: 'PENDING_IPAY88',
  FAILURE: 'FAILURE',
  COMPLETED: 'COMPLETED',
};

export const AML_STATUS = {
  APPROVED: 'Fully Verified',
  REJECTED: 'Rejected',
  PENDING: 'Details Submitted. Pending approval',
  NEW: 'New',
};

export const PERMISSIONS = {
  WEB_INVESTOR_CORP_MANAGE: 'WEB_INVESTOR_CORP_MANAGE',
  WEB_OMNIBUS_AUTHORIZE: 'WEB_OMNIBUS_AUTHORIZE',
  WEB_ISSUER_AUTHORIZE: 'WEB_ISSUER_AUTHORIZE',
};

export const AUTHORIZED_STATUS = {
  ACTIVE: 'ACTIVE',
  DEACTIVED: 'DEACTIVED',
  INVITATIONSENT: 'INVITATION_SENT',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  PENDINGTOVERIFY: 'PENDING_TO_VERIFY',
  DRAFT: 'DRAFT',
};

export const SUBMISSION_STATUS_MAPPING = {
  NEW: 'NEW',
  REWORK: 'REWORK',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PUBLISH: 'PUBLISH',
};

export const DEFAULT_MQTT_MY_ORDERS = 'MyOrders';

export const PENDING_CASH_TRANSACTIONS_ARRAY = [
  CASH_TRANSACTIONS_ACTIVITY_WALLET.BUY_ORDER_REALPAY,
  CASH_TRANSACTIONS_ACTIVITY_WALLET.BUY_ORDER_REALRECEIVE,
  CASH_TRANSACTIONS_ACTIVITY_WALLET.SELL_ORDER_REALPAY,
  CASH_TRANSACTIONS_ACTIVITY_WALLET.SELL_ORDER_REALRECEIVE,
];

export const PENDING_CASH_TRANSACTIONS_ACTIVITY_ARRAY = [
  'Buy Limit',
  'Buy Market',
  'Buy Order Refund',
  'Buy Order Paid',
  'Sell Order Received',
];

export const TOKEN_ACCOUNT_TRANSACTIONTYPE = {
  BONUS_ISSUE_TRANSFER: 'BONUS_ISSUE_TRANSFER',
  BURN: 'BURN',
  SELL_LIMIT_ORDER_DEPOSIT: 'SELL_LIMIT_ORDER_DEPOSIT',
  SELL_MARKET_ORDER_DEPOSIT: 'SELL_MARKET_ORDER_DEPOSIT',
  RIGHT_ISSUE_TRANSFER: 'RIGHT_ISSUE_TRANSFER',
  PUBLISH: 'PUBLISH',
};

export const STATUS_TRANSACTION_ORDER = {
  DONE: 'DONE',
  SUCCESSFUL: 'SUCCESSFUL',
};

export const transferSymbols = {
  addition: '+',
  subtraction: '-',
};

export const TOKEN_DEFAULT = {
  RM: 'RM',
};

export const fieldMapping: Record<string, string> = {
  'TOKEN CODE': 'tokenCode',
  'TOKEN NAME': 'tokenName',
  'ISSUE DATE': 'issueDate',
  'NUMBER OF TOKEN': 'numberOfToken',
  'CURRENT MARKET VALUE': 'currentMarketValue',
  'AVG ACQUISITION PRICE': 'avgAcquisitionPrice',
  'CURRENT MARKET PRICE': 'currentMarketPrice',
  'UNREALIZED P/L': 'unrealisedPL',
  'TOKEN STATUS': 'tokenStatus',
};

export const DEFAULT_PAGESIZE = 10;

export const FIRST_PAGE_NUMBER = 1;
