import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { routesEnum } from 'pages/Routes';
import { toast } from 'react-toastify';
import cookieOptions, { refreshTokenCookieOptions, tokenCookieOptions } from "../utils/cookieOptions";
import { logout } from './user/request';

interface FailedRequests {
  resolve: (value: AxiosResponse) => void
  reject: (value: AxiosError) => void
  config: AxiosRequestConfig
  error: AxiosError
}

export const axiosTradingInstance = axios.create({
  baseURL: process.env.WEB_API_TRADING_URL,
  withCredentials: false,
});

let failedRequests: FailedRequests[] = []
let isTokenRefreshing = false

const handleSuccess = (res: AxiosResponse) => {
  const code = res.data?.code
  if (code > 299 || code < 0) {
    return Promise.reject(res.data)
  }

  const result = res.data

  if (result.code === 500) {
    const message = res.data?.msg
    toast.error(message, {
      autoClose: 1000,
    });
    return Promise.reject(res?.data)
  }

  return result
}

const handleError = async (error: AxiosError) => {
  const originalRequest = error.config!
  const method = originalRequest?.method
  const status = error?.response?.status

  if (status !== 401) {
    if (status === 500 && method === 'get') {
      const message = error?.message
      toast.error(message, {
        autoClose: 1000,
      });
    }

    const data = error?.response?.data
    if (typeof data?.msg === 'string') {
      data.msg = [data.msg]
    }
    return Promise.reject(data)
  }

  if (isTokenRefreshing) {
    return new Promise((resolve, reject) => {
      failedRequests.push({ resolve, reject, config: originalRequest, error })
    })
  }
  
  isTokenRefreshing = true

  try {
    const response = await axios.post(`${process.env.WEB_API_URL}/users/token/refresh`, {
      refreshToken: Cookies.get('refreshToken'),
    })
    const result = response.data
    const user = result?.data
    Cookies.set('token', user?.token, tokenCookieOptions);
    Cookies.set('refreshToken', user?.refreshToken, refreshTokenCookieOptions);
    failedRequests.forEach(({ resolve, reject, config }) => {
      axiosTradingInstance(config)
        .then((resHttp) => resolve(resHttp))
        .catch((errorHttp) => reject(errorHttp))
    })
  } catch (_error: unknown) {
    failedRequests.forEach(({ reject, error: errorFailedRequest }) => reject(errorFailedRequest))
    try {
      await logout();
      localStorage.clear();
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      sessionStorage.clear();
    } catch (error) {
      localStorage.clear();
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      sessionStorage.clear();
    }
    toast.success('Session expired!', {
      autoClose: 1000,
    });
    window.location.href = routesEnum.home;
    return await Promise.reject(error)
  } finally {
    failedRequests = []
    isTokenRefreshing = false
  }

  return axiosTradingInstance(originalRequest)
}

axiosTradingInstance.interceptors.response.use(handleSuccess, handleError)

axiosTradingInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = Cookies.get('token');
    config = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: config.data,
    };

    return config;
  },
  (error) => Promise.reject(error)
)
