import * as React from 'react';

import { Contract } from 'state/contract';

import useQueryParams from 'hooks/useQueryParams';

import { removeEmptyValue } from 'utils/helpers';

const ContractProvider: React.FC = ({ children }) => {
  const query = useQueryParams();

  const initFilter = {
    ...(query.get('startDate') || query.get('endDate')
      ? {
          range: {
            startDate: new Date(query.get('startDate') || ''),
            endDate: new Date(query.get('endDate') || ''),
          },
        }
      : {}),
    ...(query.has('eventNames')
      ? {
          eventNames: removeEmptyValue(query.get('eventNames')?.trim().split(',')),
        }
      : {}),
    ...(query.has('tokenTypes')
      ? {
          tokenTypes: removeEmptyValue(query.get('tokenTypes')?.trim().split(',')),
        }
      : {}),
    ...(query.has('address')
      ? {
          address: query.get('address') || '',
        }
      : {}),
    limit: query.get('limit') === 'all' ? 'all' : Number(query.get('limit')) || 30,
    page: query.get('limit') === 'all' ? 1 : Number(query.get('page')) || 1,
  };

  return <Contract.Provider initialState={initFilter}>{children}</Contract.Provider>;
};

export default ContractProvider;
