/* eslint-disable react/jsx-no-target-blank */
import { Image, WrapperrSideBar, CollapseIconButton, collapseIcon, expandIcon } from './styled';
import { logout } from 'api/user';
import { Text } from 'components/InvestorCommon/TextV2';
import { useAppDispatch } from 'hooks/reduxHook';
import Cookies from 'js-cookie';
import { FC } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setType } from 'store/ducks/system/slice';
import { LOCAL_STORAGE, PERMISSIONS } from 'utils/constant';
import { IMAGES } from 'utils/images';

interface Props {
  menuCollapse: any;
  setMenuCollapse: any;
}

interface ISidebar {
  subMenu?: boolean;
  title: string;
  link: string;
  icon?: any;
  subItem?: ISidebar[];
}

const TOP_SIDEBAR_WITH_CORP: ISidebar[] = [
  { title: 'Home', link: '/investors', icon: <Image width="20px" height="20px" src={IMAGES.sidebar.home} /> },
  {
    subMenu: true,
    title: 'Wallet',
    link: '/investors/wallet',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.wallet} />,
    subItem: [
      {
        title: 'Wallet Details',
        link: '/investors/wallet',
      },
      {
        title: 'Token Details',
        link: '/investors/token-detail',
      },
      {
        title: 'Transactions',
        link: '/investors/transactions',
      },
      {
        title: 'Account Statement',
        link: '/investors/account-statement',
      },
    ],
  },
  {
    subMenu: true,
    title: 'Platforms',
    link: '/investors/primary-listing',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.platforms} />,
    subItem: [
      {
        title: 'Primary Listing',
        link: '/investors/primary-listing',
      },
      ...(process.env.WEB_KLDX_SHOW_TRADING_PLATFORM && process.env.WEB_KLDX_SHOW_TRADING_PLATFORM === 'TRUE'
        ? [
            {
              title: 'Trading Platform',
              link: '/investors/trading-platform',
            },
          ]
        : []),
    ],
  },
  {
    title: 'Announcement',
    link: '/investors/announcement-list',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.announcement} />,
  },
];

const TOP_SIDEBAR_WITHOUT_CORP: ISidebar[] = [
  { title: 'Home', link: '/investors', icon: <Image width="20px" height="20px" src={IMAGES.sidebar.home} /> },
  {
    subMenu: true,
    title: 'Wallet',
    link: '/investors/wallet',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.wallet} />,
    subItem: [
      {
        title: 'Wallet Details',
        link: '/investors/wallet',
      },
      {
        title: 'Token Details',
        link: '/investors/token-detail',
      },
      {
        title: 'Transactions',
        link: '/investors/transactions',
      },
      {
        title: 'Account Statement',
        link: '/investors/account-statement',
      },
      {
        title: 'Token Activity',
        link: '/investors/token-activity',
      },
    ],
  },
  {
    subMenu: true,
    title: 'Platforms',
    link: '/investors/primary-listing',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.platforms} />,
    subItem: [
      {
        title: 'Primary Listing',
        link: '/investors/primary-listing',
      },
      ...(process.env.WEB_KLDX_SHOW_TRADING_PLATFORM && process.env.WEB_KLDX_SHOW_TRADING_PLATFORM === 'TRUE'
        ? [
            {
              title: 'Trading Platform',
              link: '/investors/trading-platform',
            },
          ]
        : []),
    ],
  },
  {
    title: 'Announcement',
    link: '/investors/announcement-list',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.announcement} />,
  },
];
const TOP_SIDEBAR_WITH_OMNIBUS: ISidebar[] = [
  { title: 'Home', link: '/investors', icon: <Image width="20px" height="20px" src={IMAGES.sidebar.home} /> },
  {
    subMenu: true,
    title: 'Wallet',
    link: '/investors/wallet',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.wallet} />,
    subItem: [
      {
        title: 'Wallet Details',
        link: '/investors/wallet',
      },
      {
        title: 'Token Details',
        link: '/investors/token-detail',
      },
      {
        title: 'Transactions',
        link: '/investors/transactions',
      },
      {
        title: 'Account Statement',
        link: '/investors/account-statement',
      },
    ],
  },
  {
    subMenu: true,
    title: 'Platforms',
    link: '/investors/primary-listing',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.platforms} />,
    subItem: [
      {
        title: 'Primary Listing',
        link: '/investors/primary-listing',
      },
      ...(process.env.WEB_KLDX_SHOW_TRADING_PLATFORM && process.env.WEB_KLDX_SHOW_TRADING_PLATFORM === 'TRUE'
        ? [
            {
              title: 'Trading Platform',
              link: '/investors/trading-platform',
            },
          ]
        : []),
    ],
  },
  {
    subMenu: true,
    title: 'Child Account',
    link: '/investors/child-account',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.child} />,
    subItem: [
      {
        title: 'Account Management',
        link: '/investors/child-account/account-management',
      },
      {
        title: 'Account Holdings',
        link: '/investors/child-account/account-holdings',
      },
      {
        title: 'Notification',
        link: '/investors/child-account/notification',
      },
      {
        title: 'Internal Transactions',
        link: '/investors/child-account/internal-transactions',
      },
    ],
  },
  {
    title: 'Announcement',
    link: '/investors/announcement-list',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.announcement} />,
  },
];
const TOP_SIDEBAR_WITH_ISSUER: ISidebar[] = [
  { title: 'Home', link: '/investors', icon: <Image width="20px" height="20px" src={IMAGES.sidebar.home} /> },
  {
    title: 'My Project',
    link: '/issuer/my-project',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.project} />,
  },
  {
    subMenu: true,
    title: 'Self Service',
    link: '/issuers/seft-service',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.seft_service} />,
    subItem: [
      {
        title: 'Wallet Detail',
        link: '/issuer/wallet-detail',
      },
      {
        title: 'Transactions',
        link: '/issuer/transactions',
      },
      {
        title: 'Secondary Actions',
        link: '/secondary-actions',
      },
      {
        title: 'Reporting & Announcement',
        link: '/issuer/reporting-announcement',
      },
      {
        title: 'Documents',
        link: '/documents-issuer',
      },
    ],
  },
];
const FOOTER_SIDEBAR_WITH_CORP: ISidebar[] = [
  {
    subMenu: true,
    title: 'Settings',
    link: '/investors/setting',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.settings} />,
    subItem: [
      {
        title: 'Account',
        link: '/investors/coporate/account',
      },
      {
        title: 'Company Info',
        link: '/investors/coporate/companyInfo',
      },
      {
        title: 'User Management',
        link: '/investors/coporate/userManagement',
      },
      {
        title: 'Referral',
        link: '/investors/referral',
      },
    ],
  },
];

const FOOTER_SIDEBAR_WITHOUT_CORP: ISidebar[] = [
  {
    subMenu: true,
    title: 'Settings',
    link: '/investors/setting',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.settings} />,
    subItem: [
      {
        title: 'Account',
        link: '/investors/individual/account',
      },
      {
        title: 'Investor Info',
        link: '/investors/individual/investorInfo',
      },
      {
        title: 'Referral',
        link: '/investors/referral',
      },
    ],
  },
];

const FOOTER_SIDEBAR_WITH_ISSUER: ISidebar[] = [
  {
    subMenu: true,
    title: 'Settings',
    link: '/issuer/setting',
    icon: <Image width="20px" height="20px" src={IMAGES.sidebar.settings} />,
    subItem: [
      // {
      //   title: 'Account',
      //   link: '/issuer/account',
      // },
      {
        title: 'Issuer Info',
        link: '/issuer/issuerInfo',
      },
      {
        title: 'Documents',
        link: '/issuer/documents',
      },
      {
        title: 'User Management',
        link: '/issuer/userManagement',
      },
      {
        title: 'Referral',
        link: '/issuer/referral',
      },
    ],
  },
];

const Sidebar: FC<Props> = ({ menuCollapse, setMenuCollapse }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const permissions = localStorage.getItem(LOCAL_STORAGE.user)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.user) || '')?.permissions
    : [];

  const TOP_SIDEBAR = permissions.includes(PERMISSIONS.WEB_INVESTOR_CORP_MANAGE)
    ? TOP_SIDEBAR_WITH_CORP
    : permissions.includes(PERMISSIONS.WEB_OMNIBUS_AUTHORIZE)
    ? TOP_SIDEBAR_WITH_OMNIBUS
    : permissions.includes(PERMISSIONS.WEB_ISSUER_AUTHORIZE)
    ? TOP_SIDEBAR_WITH_ISSUER
    : TOP_SIDEBAR_WITHOUT_CORP;

  const FOOTER_SIDEBAR = permissions.includes(PERMISSIONS.WEB_INVESTOR_CORP_MANAGE)
    ? FOOTER_SIDEBAR_WITH_CORP
    : permissions.includes(PERMISSIONS.WEB_ISSUER_AUTHORIZE)
    ? FOOTER_SIDEBAR_WITH_ISSUER
    : FOOTER_SIDEBAR_WITHOUT_CORP;

  const location = useLocation();

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    sessionStorage.clear();
    toast.success('Logout successfully!', {
      autoClose: 1000,
    });
    dispatch(setType(''));

    if (process.env.WEB_KLDX_SHOW_TRADING_PLATFORM === 'TRUE') {
      const redirectUrl = process.env.WEB_KLDX_NEW_URL;
      const url = `${redirectUrl}/?logout=true`;
      window.location.href = url;
    }

  };
  return (
    <WrapperrSideBar className="large-screen" style={{ width: !menuCollapse ? '290px' : '100px' }}>
      <CollapseIconButton className={`${menuCollapse ? 'expand' : 'collapse'}`}>
        <img src={menuCollapse ? expandIcon : collapseIcon} alt="" onClick={() => setMenuCollapse()} />
      </CollapseIconButton>

      <ProSidebar collapsed={menuCollapse}>
        <SidebarContent
          style={{
            marginTop: '20px',
          }}
        >
          {/* <Menu iconShape="square"> */}
          {TOP_SIDEBAR.map((item, index) =>
            !item.subMenu ? (
              <Menu iconShape="square" key={item.link}>
                <MenuItem
                  active={item.link === location.pathname || (index === 0 && location.pathname === '/')}
                  key={item.link}
                  icon={item.icon}
                  onClick={() => history.push(item?.link || '')}
                  style={{
                    font: 'normal normal normal 16px Lato',
                  }}
                >
                  {item.title}
                </MenuItem>
              </Menu>
            ) : (
              <Menu
                key={item.link}
                // style={{
                //   font: 'normal normal normal 16px Lato',
                // }}
              >
                <SubMenu
                  suffix={<span className="badge yellow"></span>}
                  title={item.title}
                  icon={item.icon}
                  style={{
                    font: 'normal normal normal 16px Lato',
                  }}
                >
                  {item.subItem?.map((item: ISidebar) => (
                    <MenuItem
                      key={item.link}
                      active={item.link === location.pathname}
                      onClick={() => history.push(item?.link || '')}
                      style={{
                        font: 'normal normal normal 16px Lato',
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </SubMenu>
              </Menu>
            )
          )}
          {/* </Menu> */}
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            <MenuItem
              icon={<Image width="20px" height="20px" src={IMAGES.sidebar.helpCenter} />}
              style={{
                font: 'normal normal normal 16px Lato',
              }}
            >
              <a href={`${process.env.WEB_URL_HELP_CENTER}`} target="_blank" style={{ color: '#FFFFFF' }} rel="noopener">
                Help Center
              </a>
            </MenuItem>
          </Menu>
          {FOOTER_SIDEBAR.map((item, index) =>
            !item.subMenu ? (
              <Menu key={item.link}>
                <MenuItem
                  onClick={() => history.push(item?.link || '')}
                  active={item.link === location.pathname}
                  key={item.link}
                  icon={item.icon}
                  style={{
                    font: 'normal normal normal 16px Lato',
                  }}
                >
                  {item.title}
                </MenuItem>
              </Menu>
            ) : (
              <Menu key={item.link}>
                <SubMenu
                  suffix={<span className="badge yellow"></span>}
                  title={item.title}
                  icon={item.icon}
                  style={{
                    font: 'normal normal normal 16px Lato',
                  }}
                >
                  {item.subItem?.map((item: ISidebar) => (
                    <MenuItem
                      key={item.link}
                      active={item.link === location.pathname}
                      onClick={() => history.push(item?.link || '')}
                      style={{
                        font: 'normal normal normal 16px Lato',
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </SubMenu>
              </Menu>
            )
          )}
          <Menu iconShape="square">
            <MenuItem
              onClick={handleLogout}
              icon={<Image width="20px" height="20px" src={IMAGES.sidebar.logOut} />}
              style={{
                font: 'normal normal normal 16px Lato',
              }}
            >
              Logout
            </MenuItem>
            <Text
              style={{
                color: '#fff6f6',
                font: 'normal normal normal 11px Lato',
              }}
            >
              Kapital DX™ Sdn Bhd Registration Number 202001022343 (1378663-V). All rights reserved.
            </Text>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </WrapperrSideBar>
  );
};

export default Sidebar;
