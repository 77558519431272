import { IPaginationRequest } from './../types';
import { checkChangePassword, checkChangePin } from './request';
import {
  AmountWithdrawFee,
  ICashTransactionResponse,
  IIssuerAnnouncement,
  IListUserManagement,
  IMessagesResponse,
  ITokenDetailResponse,
  ITradingAccountData,
  IUseMytradesOrderMarketParams,
  IUseMytradesOrderMarketResponse,
  IWalletResponse,
} from './types';
import { axiosInstance } from 'api/axios';
import { axiosTradingInstance } from 'api/axiosTrading';
import { QueryConfig, useQuery } from 'react-query';

export const useGetVerifyInvestor = (token: string) => {
  return useQuery<any>(['/investors/verify'], async () => {
    const data = await axiosInstance.get(`/investors/verify?token=${token}`);
    return data;
  });
};

export const useCheckNeedChangePassword = () => {
  return useQuery('check-need-change-password', checkChangePassword);
};

export const useCheckNeedChangePin = () => {
  return useQuery('check-need-change-pin', checkChangePin);
};

export const useGetFinancialInformation = (id: string, options?: any) => {
  return useQuery<any>(
    ['/investors/financial-information'],
    async () => {
      const data = await axiosInstance.get(`/investors/${id}/financial-information`);
      return data;
    },

    options
  );
};

export const useGetPersonalInformation = (id: string | number, options?: any) => {
  return useQuery<any>(
    ['/investors/personal-information'],
    async () => {
      const data = await axiosInstance.get(`/investors/${id}/personal-information`);
      return data;
    },
    options
  );
};

export const useGetInvestorType = (options?: any) => {
  return useQuery<any>(
    ['/investors/investor-types'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/investor-types`);
      const temp = data.map((item: any) => {
        return { ...item, value: item.id, label: item.name };
      });
      return temp || [];
    },
    options
  );
};

export const useGetRiskProfile = (options?: any) => {
  return useQuery<any>(
    ['/investors/risk-profiles'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/risk-profiles`);
      const temp = data.map((item: any) => {
        return { ...item, value: item.id, label: item.name };
      });
      return temp || [];
    },
    options
  );
};

export const useGetInvestorStep = (options?: any) => {
  return useQuery<any>(
    ['/investors/steps/process'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/steps/process`);
      return data;
    },
    options
  );
};

export const useInvestorDetails = (options?: any) => {
  return useQuery<any>(
    ['/investors/details'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/details`);
      return data;
    },
    options
  );
};

export const useGetMyPortFolio = (options?: any) => {
  return useQuery<any>(
    ['/investors/my-portfolio'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/my-portfolio`);
      return data;
    },
    options
  );
};

export const useGetInvestorMessage = (options?: any) => {
  return useQuery<IMessagesResponse[]>(
    ['/investors/messages'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/messages`);
      return data;
    },
    options
  );
};

export const useGetInvestorSetting = (options?: any) => {
  return useQuery<any>(
    ['/investors/profile-settings'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/profile-settings`);
      return data;
    },
    options
  );
};

export const useGetInvestorWallet = (params: any, options?: any) => {
  return useQuery<IWalletResponse>(
    ['/investors/wallet'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/wallet`, { params });
      return data;
    },
    options
  );
};

export const useGetTokenDetails = (params?: IPaginationRequest, options?: any) => {
  return useQuery<ITokenDetailResponse>(
    ['/investors/token', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/investors/token`, { params });
      return data;
    },
    options
  );
};

export const useGetCashTransactions = (params?: IPaginationRequest, options?: any) => {
  return useQuery<ICashTransactionResponse>(
    ['/investors/wallet/transactions', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/investors/wallet/transactions`, { params });
      return data;
    },
    options
  );
};
export const useGetListStatement = (params: any, options?: any) => {
  return useQuery<any>(
    ['/statement/statement-can-be-downloaded', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/statement/statement-can-be-downloaded`, { params });
      return data;
    },
    options
  );
};
export const useGetMainCategories = () => {
  return useQuery<any>(['/announcements/main-categories'], async () => {
    const { data } = await axiosInstance.get(`/announcements/main-categories`);
    return data;
  });
};

// CIP3
export const useGetListActorWithType = (
  actorType: string,
  params?: {
    order?: string;
    sortBy?: string;
  }
) => {
  const { data, refetch } = useQuery<any>(
    [`/corporate-investors/actor-with-type?actorType=${actorType}`, params?.sortBy, params?.order],
    async () => {
      let url = `/corporate-investors/actor-with-type?actorType=${actorType}`;
      if (params?.order && params?.sortBy) {
        url += `&sortBy=${params?.sortBy ?? 'defaultSortBy'}&order=${params?.order ?? 'defaultOrder'}`;
      }
      const { data } = await axiosInstance.get(url);
      return data?.data ? data?.data : data;
    }
  );

  return { data, refetch };
};

export const useGetList =
  (type: 'COMPANY' | 'KLDX_PLATFORM') =>
  (
    params?: {
      page: number;
      searchParam?: string;
      category?: string;
      fromDate?: string;
      toDate?: string;
      order?: string;
      sortBy?: string;
    },
    options?: any
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<any>(
      ['/announcements', params?.page, params?.sortBy, params?.order],
      async () => {
        let url = `/announcements?page=${params?.page}&pageSize=20&type=${type}`;
        if (params?.order && params?.sortBy) {
          url += `&sortBy=${params?.sortBy ?? 'defaultSortBy'}&order=${params?.order ?? 'defaultOrder'}`;
        }
        if (params?.searchParam) {
          url += `&searchParam=${encodeURIComponent(params.searchParam)}`;
        }
        if (params?.category !== 'Please select' && params?.category) {
          url += `&category=${encodeURIComponent(params.category)}`;
        }

        if (params?.fromDate && params?.toDate) {
          url += `&fromDate=${params.fromDate} 00:00:00&toDate=${params.toDate} 23:59:59`;
        }
        const { data } = await axiosInstance.get(url);
        return data;
      },
      options
    );
  };

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useGetListCompany = useGetList('COMPANY');
// eslint-disable-next-line react-hooks/rules-of-hooks
export const useGetListPlatform = useGetList('KLDX_PLATFORM');

export const useGetListAnnouncementByProjectid = (
  params?: {
    page: number;
    searchParam?: string;
    category?: string;
    fromDate?: string;
    toDate?: string;
    order?: string;
    sortBy?: string;
    projectId?: string;
  },
  options?: any
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery<any>(
    ['/announcements/{projectId}', params?.page, params?.sortBy, params?.order, params?.searchParam],
    async () => {
      let url = `/announcements/${params?.projectId}?&page=${params?.page}&pageSize=20`;
      if (params?.order && params?.sortBy) {
        url += `&sortBy=${params?.sortBy ?? 'defaultSortBy'}&order=${params?.order ?? 'defaultOrder'}`;
      }
      if (params?.searchParam) {
        url += `&searchParam=${encodeURIComponent(params.searchParam)}`;
      }
      if (params?.category !== 'Please select' && params?.category) {
        url += `&category=${encodeURIComponent(params.category)}`;
      }

      if (params?.fromDate && params?.toDate) {
        url += `&fromDate=${params.fromDate} 00:00:00&toDate=${params.toDate} 23:59:59`;
      }
      const { data } = await axiosInstance.get(url);
      return data;
    },
    options
  );
};

export const useGetTokenTransactions = (params?: IPaginationRequest, options?: any) => {
  return useQuery<any>(
    ['/investors/token/transactions', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/investors/token/transactions`, { params });
      return data;
    },
    options
  );
};

export const useGetTradingAccountTransactions = (params?: IPaginationRequest, options?: any) => {
  return useQuery<ITradingAccountData>(
    ['/transaction/trading-accounts', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/transaction/trading-accounts`, { params });
      return data;
    },
    options
  );
};

export const useGetListCountry = (options?: any) => {
  return useQuery<any>(
    ['/settings/countries'],
    async () => {
      const { data } = await axiosInstance.get(`/settings/countries`);
      return data;
    },
    options
  );
};

export const useGetListIndustry = (options?: any) => {
  return useQuery<any>(
    ['/settings/industries'],
    async () => {
      const { data } = await axiosInstance.get(`/settings/industries`);
      return data;
    },
    options
  );
};

export const useGetListProgress = (options?: any, reload?: any) => {
  return useQuery<any>(
    ['/investors/verification/progress', reload],
    async () => {
      const { data } = await axiosInstance.get(`/investors/verification/progress`);
      return data;
    },
    options
  );
};

export const useGetPersonalDetail = (options?: any) => {
  return useQuery<any>(
    ['/investors/personal-info'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/personal-info`);
      return data;
    },
    options
  );
};
export const useGetMetadataCorporate = (options?: any) => {
  return useQuery<any>(
    ['/metadata/corporate-investors'],
    async () => {
      const { data } = await axiosInstance.get(`/metadata/corporate-investors`);
      return data;
    },
    options
  );
};
export const useGetInvestorProfileCorporate = (options?: any) => {
  return useQuery<any>(
    ['/corporate-investors/profile'],
    async () => {
      const res = await axiosInstance.get(`/corporate-investors/profile`);
      return res.data;
    },
    options
  );
};
export const useGetEmploymentDetail = (options?: any) => {
  return useQuery<any>(
    ['/investors/employee-info'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/employee-info`);
      return data;
    },
    options
  );
};

export const useGetEddInformation = (options?: any) => {
  return useQuery<any>(
    ['/investors/get/edd'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/get/edd`);
      return data;
    },
    options
  );
};

export const useGetInvestorProfile = (options?: any) => {
  return useQuery<any>(
    ['/investors/investing-info'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/investing-info`);
      return data;
    },
    options
  );
};

export const useGetVerification = (options?: any) => {
  return useQuery<any>(
    ['/ctos/create-transaction'],
    async () => {
      const { data } = await axiosInstance.get(`/ctos/create-transaction`);
      return data;
    },
    options
  );
};

export const useGetDeclarationInfo = (options?: any) => {
  return useQuery<any>(
    ['/investors/declaration-info'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/declaration-info`);
      return data;
    },
    options
  );
};

export const useGetVerifyToken = (params: { token: string }, options?: any) => {
  return useQuery<any>(
    ['/users/verify-reset-password-token', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/users/verify-reset-password-token`, { params });
      return data;
    },
    options
  );
};

export const useGetMetaData = (options?: any) => {
  return useQuery<any>(
    ['/metadata/v2'],
    async () => {
      const { data } = await axiosInstance.get(`/metadata/v2`);
      return data;
    },
    options
  );
};

export const useGetNotification = (userId: string, options?: any) => {
  return useQuery<any>(
    ['/user/notifications'],
    async () => {
      const response = await axiosInstance.get(`/user/notifications?user_id=${userId}`);
      return response;
    },
    options
  );
};
export const useGetNotificationV2 = (furthestDate: string, userId?: string, options?: any) => {
  return useQuery<any>(
    ['/user/notifications-v2'],
    async () => {
      const response = await axiosInstance.get(
        `/user/get-notification-list-had-limit?limit=50&furthestDate=${furthestDate}`
      );
      return response;
    },
    options
  );
};
export const usePaymentToProcess = (params?: IPaginationRequest, options?: any) => {
  return useQuery<any>(['/investors/token/transactions', { params }], () => {
    return new Promise((rel, rej) => {
      setTimeout(() => {
        rel({
          transactions: Array.from({ length: 10 }).map((_, index) => {
            return {
              id: index,
              date: '18-7-2022',
              investorName: 'Hui Sing',
              investorImage:
                'https://play-lh.googleusercontent.com/MtAnwAXFymTtu3_8rRNw78aPpOfFHHUPuTa2aj3jxAiCD4xGL7g4fZAS0sRVdRSrcwhf',
              transaction: 'Fees and charges',
              method: 'Bank Transfer',
              amount: 'RM5000',
              status: 'To Process',
            };
          }),
          currentPage: 1,
          pageSize: 10,
          totalPage: 10,
          totalTransaction: 100,
        });
      }, 1000);
    });
  });
};

export const useGetBankAccountInfo = (params: any, options?: any) => {
  return useQuery<any>(
    ['/investors/wallet'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/wallet`, { params });
      return data;
    },
    options
  );
};

export const useGetAmountWithdrawFee = (request: AmountWithdrawFee) => {
  const result = useQuery<any>(['/investors/withdraw/fee?', request.amountWithdraw], async () => {
    const data = await axiosInstance.get(`/investors/withdraw/fee?amountWithdraw=${request.amountWithdraw}`);
    return data;
  });
  return result;
};

export const useGetListBank = () => {
  return useQuery<any>(['/bank'], async () => {
    const { data } = await axiosInstance.get('/bank');
    return data;
  });
};

export const useSearchSubscription = (searching: string) => {
  return useQuery<any>(['/searchSubscription'], async () => {
    const data = await axiosInstance.get(`/investors/details/search-subscription?searching=${searching}`);
    return data;
  });
};

export const useMytradesOrderMarket = (
  params: IUseMytradesOrderMarketParams,
  queryConfig?: QueryConfig<IUseMytradesOrderMarketResponse>
) => {
  return useQuery<IUseMytradesOrderMarketResponse>(
    ['/market/order/my-trades'],
    async () => {
      const data: IUseMytradesOrderMarketResponse = await axiosTradingInstance.get(`/market/order/my-trades`, {
        params,
      });
      return data;
    },
    queryConfig
  );
};

export const useDownloadCSVTokenAccount = (params: any) => {
  return useQuery<any>(['/investors/token/transactions/download'], async () => {
    const { data } = await axiosInstance.get(`/investors/token/transactions/download?${new URLSearchParams(params)}`);
    return data;
  });
};
export const useGetSubscriptionOmnibus = (params?: IIssuerAnnouncement, options?: any) => {
  return useQuery<any>(
    ['/omnibus-account/subscription', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/omnibus-account/subscription`, { params });
      return data;
    },
    options
  );
};
export const useDownloadCSVCashAccount = (params: any) => {
  return useQuery<any>(['/investors/wallet/transactions/download'], async () => {
    const { data } = await axiosInstance.get(`/investors/wallet/transactions/download?${new URLSearchParams(params)}`);
    return data;
  });
};

export const useGet2FAGenerate = (options?: any) => {
  return useQuery<any>(
    ['/users/2fa/generate'],
    async () => {
      const { data } = await axiosInstance.get(`/users/2fa/generate`);
      return data;
    },
    options
  );
};
export const useGetTopUpRefundFee = (options?: any, fee?: number) => {
  return useQuery<any>(
    ['/top-up-refund-fee'],
    async () => {
      const { data } = await axiosInstance.get(`/investors/topup/refund/fee?amount=${fee}`);
      return data;
    },
    options
  );
};

export const useGetIssuerAnnouncement = (params?: IIssuerAnnouncement) => {
  return useQuery<any>(['/announcements/notifications', { params }], async () => {
    const { data } = await axiosInstance.get(`/announcements/notifications`, { params });
    return data;
  });
};

export const useGetIssuerAnnouncementDetail = (id?: number | string) => {
  return useQuery<any>([`/announcements/detail?`, id], async () => {
    const { data } = await axiosInstance.get(`/announcements/detail?id=${id}`);
    return data;
  });
};
export const useGetDataDetailSubscription = (id: number | string, params: any) => {
  return useQuery<any>([`/omnibus-account/subscription?`, id, params], async () => {
    const { data } = await axiosInstance.get(`/omnibus-account/subscription/${id}?${new URLSearchParams(params)}`);
    return data;
  });
};
export const useGetListUserManagement = (params: IListUserManagement) => {
  return useQuery<any>(['/authorized-person?', { params }], async () => {
    const { data } = await axiosInstance.get(`/authorized-person`, { params });
    return data?.data ? data?.data : data;
  });
};

export const useGetUserManagement = (params: { id: number }) => {
  return useQuery<any>(
    ['/get-authorized-person?', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/authorized-person/${params?.id}`);
      return data?.data ? data?.data : data;
    },
    { enabled: params?.id }
  );
};
export const useGetListTokenCode = (id?: number | string) => {
  return useQuery<any>([`/omnibus-account/token-transfer/tokens?`, id], async () => {
    const { data } = await axiosInstance.get(`/omnibus-account/token-transfer/tokens?senderId=${id}`);
    return data;
  });
};
export const useGetDetailActorCorporate = (params?: any, options?: any) => {
  return useQuery<any>(
    ['/corporate-investors/actor', { params }],
    async () => {
      const { data } = await axiosInstance.get(`/corporate-investors/actor`, { params });
      return data;
    },
    options
  );
};
